// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selected: null,
  capacitation: null, 
  users: []
}

const capacitations = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_CAPACITATIONS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_USERS':
      return {
        ...state,
        users: action.users
      }
    case 'ADD_DOCUMENT':
      return { ...state }
    case 'DELETE_DOCUMENT':
      return { ...state }
    case 'GET_CAPACITATION':
      return { ...state, selected: action.selected }
    case 'GET_CAPACITATION_DETAIL':
      return { ...state, capacitation: action.capacitation }
    case 'EDIT_DOCUMENT':
      return { ...state }
    default:
      return { ...state }
  }
}
export default capacitations
