// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedEmployee: null,
  currentUser: null,
  states: [],
  paymentsProviderByYearCard:[],
  categoriesByType:[],
  inventoryProjects:[],
  currentProject:null,
  upcomingMaintenances:[],
  pendingResponsives:[],
  selectedActive:null
}

const inventory_actives = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { 
        ...state, 
        allData: action.data,
        params: action.params
      }
    case 'GET_INVENTORY_ACTIVES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_INVENTORY_INACTIVES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PAYMENTS_PROVIDER_BY_YEAR_CARD':
      return { ...state, paymentsProviderByYearCard: action.data}
    case 'GET_PAYMENTS_BY_TYPE':
      return { ...state, paymentsByType: action.data}
    case 'GET_CATEGORIES_BY_TYPE':
      return {
          ...state, 
          categoriesByType: action.data,
          params: action.params
        }
    case 'GET_UPCOMMING_MAINTENANCES':
      return { 
        ...state, 
        upcomingMaintenances: action.data,
        params: action.params
      }
    case 'GET_PENDING_RESPONSIVES':
      return { 
        ...state, 
        pendingResponsives: action.data,
        params: action.params
      }
    case 'GET_INVENTORY_ACTIVE':
      return { ...state, selectedActive: action.selectedActive }
    case 'GET_INVENTORY_PROJECTS':
      return {
        ...state, 
        inventoryProjects: action.inventory_project,
        params: action.params
      }
    
    default:
      return { ...state }
  }
}
export default inventory_actives
