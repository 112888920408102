// ** Initial State
const initialState = {
  events: [],
  selectedEvent: {},
  selectedCalendars: [],
  selectedLabel: {},
  tags: [],
  params: {
    tag: ''
  }
}

const calenderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_EVENTS':
      return { ...state, events: action.events, params: action.params }
    case 'ADD_EVENT':
      return { ...state }
    case 'REMOVE_EVENT':
      return { ...state }
    case 'UPDATE_EVENT':
      return { ...state }
    
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    case 'GET_LABELS':
      return {...state, selectedCalendars: action.selectedCalendars, tags: action.selectedCalendars}
    case 'DELETE_LABEL':
      return { ...state }
    case 'GET_LABEL':
        return { ...state, selectedLabel: action.selectedLabel }
    case 'EDIT_LABEL':
        return { ...state }
    default:
      return state
  }
}

export default calenderReducer
