// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selected: null,
  states: []  
}

const inventory_categories = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_INVENTORY_CATEGORIES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'ADD_OFFICE':
      return { ...state }
    case 'DELETE_INVENTORY_CATEGORY':
      return { ...state }
    case 'GET_INVENTORY_CATEGORY':
      return { ...state, selected: action.selected }
    case 'GET_STATES':
      return { ...state, states: action.states }
    case 'EDIT_INVENTORY_CATEGORY':
      return { ...state }
    default:
      return { ...state }
  }
}
export default inventory_categories
