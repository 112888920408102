// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedEmployee: null,
  currentUser: null,
  states: [],
  paymentsProviderByYearCard:[],
  paymentsByType:[],
  paymentsRefund:[],
  paymentsByQ:[],
  paymentsMonthly: [],
  paymentsByEmployee: [],
  selectedPayment:null
}

const payments = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_PAYMENTS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_REFUNDS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_REFUNDS_TO_AUTH':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PAYMENTS_PROVIDER_BY_YEAR_CARD':
      return { ...state, paymentsProviderByYearCard: action.data}
    case 'GET_PAYMENTS_PAYMENTS_MONTHLY':
      return { ...state, paymentsMonthly: action.data}

    case 'GET_PAYMENTS_BY_TYPE':
      return { ...state, paymentsByType: action.data}

    case 'GET_PAYMENTS_REFUND':
      return { ...state, paymentsRefund: action.data}

    case 'GET_PAYMENTS_BY_Q':
      return { ...state, paymentsByQ: action.data}

    case 'GET_PAYMENTS_BY_EMPLOYEE':
      return { ...state, paymentsByEmployee: action.data}

    case 'GET_PAYMENT':
      return { ...state, selectedPayment: action.selectedPayment }
    case 'EDIT_PAYMENT':
      return { ...state }
    case 'CLONE_PAYMENT':
      return { ...state }
    case 'RESET':
      return initialState //Always return the initial state
    default:
      return { ...state }
  }
}
export default payments
