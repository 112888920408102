// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedEmployee: null,
  currentUser: null,
  states: [],
  paymentsProviderByYearCard:[],
  categoriesByType:[],
  upcomingMaintenances:[],
  pendingResponsives:[],
  selectedHoliday:null,
  holidaysFestives: []
}

const holidays = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_INVENTORY_INACTIVES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PAYMENTS_PROVIDER_BY_YEAR_CARD':
      return { ...state, paymentsProviderByYearCard: action.data}

    case 'GET_PAYMENTS_BY_TYPE':
      return { ...state, paymentsByType: action.data}

    case 'GET_CATEGORIES_BY_TYPE':
      return { ...state, categoriesByType: action.data}

    case 'GET_UPCOMMING_MAINTENANCES':
      return { ...state, upcomingMaintenances: action.data}

    case 'GET_PENDING_RESPONSIVES':
      return { ...state, pendingResponsives: action.data}

    case 'GET_HOLIDAY':
      return { ...state, selectedHoliday: action.selectedHoliday }

    case 'GET_HOLIDAYS':
      return { ...state, holidaysFestives: action.data }
    
    default:
      return { ...state }
  }
}
export default holidays
