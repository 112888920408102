// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedOffice: null,
  states: []  
}

const offices = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_OFFICES':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'ADD_OFFICE':
      return { ...state }
    case 'DELETE_OFFICE':
      return { ...state }
    case 'GET_OFFICE':
      return { ...state, selectedOffice: action.selectedOffice }
    case 'GET_STATES':
      return { ...state, states: action.states }
    case 'EDIT_OFFICE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default offices
